/* eslint-disable no-undef */
import { shallowReactive, watch, computed, onBeforeMount } from "vue";
import { usePage, router } from '@inertiajs/vue3';
import { blockEditorblocks, promotionCreativeNames } from "g/Scripts/Analytics/Constants.js";

// TODO
// TODO!: Vaatii TODELLA ison refaktoroinnin!
// TODO

const $page = usePage();

const currency = computed(() => $page.props?.currency?.shortName ?? "EUR");

const cart = computed(() => $page.props?.cart ?? null);

const cartItems = computed(() => {
  if (cart.value) {
    return (cart.value?.cartitems ?? []).map(function (row, index) {
      return {
        item_id: row?.products_number ?? row.products_id,
        item_name: row.name,
        currency: currency.value,
        discount: parseFloat(row.price_data.discount_amount_with_vat),
        price: parseFloat(row.price_data.price_without_discount_with_vat),
        index: index + 1,
        quantity: row.amount_cart,
      };
    });
  }
  return [];
});

const contactGroups = computed(() => {
  const contact = $page.props?.contact ?? null;
  let groups = "";
  if (contact?.groups?.length) {
    groups = contact.group_numbers.sort().join(',');
  }
  return groups;
});

// HELPERS

function isArray(thing) {
  return thing != null && thing.constructor && thing.constructor.name === "Array";
}

function isObject(thing) {
  return thing != null && thing.constructor && thing.constructor.name === "Object";
}

function gtagEnabled() {
  return import.meta.env?.VITE_GOOGLE_GTAG_ACCOUNT && typeof gtag === "function";
}

function gtmEnabled() {
  return import.meta.env?.VITE_GOOGLE_GTM_ACCOUNT && typeof gtag === "function";
}

// END HELPERS

export function useAnalytics() {
  if (
    import.meta.env.VITE_SEND_ANALYTICS !== "true" ||
    typeof window === "undefined"
  ) {
    return false;
  }

  // console.log("Using analytics");
  // Navigation events

  onBeforeMount(() => router.on("navigate", pageChange));

  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events
  // https://developers.google.com/tag-platform/devguides/consent

  const gtag_pageview = function (
    analyticsEvent,
    pageComponentName,
    pageProps,
    pageUrl,
  ) {

    // Hox: lähtee automaattisesti, manuaalinen  määritys vaatii:
    // gtag('config', "G-84XPX2KYNL", { 'send_page_view': false });
    // Mutta stagessa lähti silti tuplana jostain syystä
    // gtag('event', 'page_view', { 'page_location': window.location.origin + pageUrl });

    // Facebook page view event
    if (window.fbq) {
      window.fbq('track', "PageView", { eventID: $page.props.eventId });
    }

    const contact = $page.props.contact;

    //Tämä aina kun sivu latautuu
    switch (analyticsEvent) {
      case 'login':

        gsend('login', {
          method: 'standard',
        });

        if (contact?.length) {
          gsend('user_info', {
            'user_id': contact?.number,
            'user_group': contactGroups.value,
          });
        }

        break;
      case 'register':
        gsend('sign_up', {
          method: 'standard',
        });
        break;
    }

    if (pageComponentName === "Frontpage") {


      let blocks = pageProps?.blocks ?? [];

      let viewPromotion = "view_promotion";

      // BLOCKEDITOR ITEM SLIDERS
      if (blocks.length) {

        let blockProductSliders = blocks.filter((item) => item.name == blockEditorblocks.BLOCK_GROUP_HIGHLIGHT) ?? [];
        if (blockProductSliders?.length) {

          blockProductSliders.forEach(slider => {
            let products = slider.props?.elements?.products ?? [];
            let listName = slider.props?.elements?.name ?? "Frontpage";
            viewItemListEvent(products, listName);
          });

        }

        // DYNAMIC BANNERS
        let blockBanners = blocks.filter((item) => item.name == blockEditorblocks.BLOCK_DYNAMIC_BANNER) ?? [];
        blockDynamicBanners(viewPromotion, blockBanners);

        // DEFAULT BANNERS
        let blockBanners2 = blocks.filter((item) => item.name == blockEditorblocks.BLOCK_DEFAULT_BANNER) ?? [];
        blockDefaultBanners(viewPromotion, blockBanners2);

        // DEFAULT GROUP PROMOTIONS
        let blockGoups = blocks.filter((item) => item.name == blockEditorblocks.BLOCK_GROUP_HIGHLIGHT) ?? [];
        blockDefaultGroups(viewPromotion, blockGoups);

      } else { // WEBSHOP PAGE SETTINGS

        // PRODUCT SLIDERS
        let productSliders = pageProps?.products ?? [];
        if (productSliders.length) {

          productSliders.forEach(slider => {
            let products = slider?.products ?? [];
            let listName = slider?.name ?? "Frontpage";
            viewItemListEvent(products, listName);
          });
        }

        // BANNERS
        let banners = Object.values(pageProps?.banners?.['banner'] ?? {}) ?? [];
        if (banners.length) {
          banners.forEach((banner, index) => {
            let item = getPromotionTemplate(banner, index, promotionCreativeNames.BANNER);
            basicPromotionEvent(viewPromotion, item);
          });
        }

        // NOSTOT
        let promos = Object.values(pageProps?.banners?.['promo'] ?? {}) ?? [];
        if (promos.length) {
          promos.forEach((promo, index) => {
            let item = getPromotionTemplate(promo, index, promotionCreativeNames.PROMO);
            basicPromotionEvent(viewPromotion, item);
          });
        }

      }

    } else if (pageComponentName === "Product") {
      const groups = pageProps.breadcrumbs
        .map((crumb, index) => {
          if (index && index < pageProps.breadcrumbs.length - 1) {
            return crumb.name;
          }
        })
        .filter((n) => n);

      let item = {
        item_id: pageProps.product.number,
        item_name: pageProps.product.name,
        affiliation: window.location.host ?? "",
        currency: currency.value,
        discount: parseFloat(
          pageProps.product.price_data.total_discount_amount_with_vat,
        ),
        price: parseFloat(
          pageProps.product.price_data.total_price_without_discount_with_vat,
        ),
        quantity: 1,
        availability: pageProps?.analytics?.availability ?? "",
      };

      groups.forEach((group, index) => {
        item[index ? "item_category" + (index + 1) : "item_category"] = group;
      });

      let data = {
        currency: currency.value,
        value: parseFloat(pageProps.product.price_data.price_with_vat),
        items: [item],
      };

      gsend("view_item", setEcommerce(data));

      // Custobar product view event
      if (window.cstbr) {
        if (window.cstbrConfig) {
          window.cstbrConfig.productId = pageProps.product.id;
        }
        window.cstbr.push({ type: 'VIEW', product_id: pageProps.product.id });
      }

      // Facebook product page view event
      if (window.fbq) {
        window.fbq('track', 'ViewContent', {
          content_name: pageProps.product.name,
          content_category: (groups && groups.length) ? groups.join(" > ") : "",
          content_ids: [pageProps.product.id],
          content_type: 'product',
          value: parseFloat(pageProps.product.price_data.price_with_vat),
          currency: currency.value,
        }, { eventID: $page.props.eventId });
      }

    } else if (pageComponentName === "Cart") {

      let cartTotal = parseFloat((cart.value?.display_price.total_price.total_price_with_vat ?? "0").replace(/[^0-9.,]/g, '').replace(',', '.'));

      let data = {
        currency: currency.value,
        value: cartTotal,
        items: cartItems.value,
      };

      gsend('begin_checkout', setEcommerce(data));

    } else if (pageComponentName === "ProductList") {
      // console.log('ProductList view', pageProps.products.data);

      const url = pageProps.lazyload_url;

      const pageType = shallowReactive({
        isSearch: url.includes('/search/'),
        isBrand: url.includes('/brands/'),
        isCampaign: url.includes('/campaigns/'),
      });

      pageType.isCategory = Object.keys(pageType).every((k) => !pageType[k]);

      if (pageType.isSearch) {
        gsend("search", {
          search_term: pageProps.search_term ?? "",
        });
      }

      if (!pageType.isCategory) {
        return;
      }

      let products = pageProps.products?.data ?? [];

      viewItemListEvent(products);

    } else if (pageComponentName === "Confirmation") {

      if (analyticsEvent === "confirmation") {
        const summary = pageProps.order.summary;

        if (!summary || !Array.isArray(summary.rows)) {
          return false;
        }

        const items = summary.rows.map((row, index) => {
          return {
            item_id: row?.products_number ?? row.products_id,
            item_variant: row.item_variant,
            item_name: row.name,
            currency: summary.currency,
            discount: parseFloat(row.price_data.discount_amount_with_vat),
            price: parseFloat(row.price_data.price_without_discount_with_vat),
            item_category: row.group_name,
            quantity: row.amount_cart,
          };
        });

        // Enhanced conversions
        let orderInfo = pageProps.order?.info;

        if (orderInfo && isObject(orderInfo)) {
          gsend('user_data', orderInfo, 'set');
        }

        if (import.meta.env.VITE_GOOGLE_ADS_CONVERSION_ID && import.meta.env.VITE_GOOGLE_ADS_CONVERSION_LABEL) {
          gsend('conversion', {
            'send_to': import.meta.env.VITE_GOOGLE_ADS_CONVERSION_ID + '/' + import.meta.env.VITE_GOOGLE_ADS_CONVERSION_LABEL,
            'value': summary.total,
            'currency': summary.currency,
            'transaction_id': summary.order_number,
          });
        }

        let a = {
          user_id: contact?.number || 0,
          user_group: contactGroups.value,
        };

        let b = {
          transaction_id: summary.order_number,
          value: summary.total,
          tax: summary.total_tax,
          shipping: summary.shipping,
          currency: summary.currency,
          items: items,
        };

        gsend("purchase", setEcommerce(a, b));

        // Facebook purchase event
        if (window.fbq) {
          const ids = items.map(item => item.item_id);
          window.fbq('track', 'Purchase', { content_ids: ids }, { currency: summary.currency, value: summary.total }, { eventID: $page.props.eventId });
        }

      }
    }
  };

  const pageChange = (event) => {
    // TODO: if cookies are not accepted -> return false

    // Vaikuttais että propsit ei tule mukana kun kutsuu resettiä ennen eventtiä:
    // https://developers.google.com/tag-platform/tag-manager/datalayer#custom_data_layer_methods
    resetDataLayer();

    const analyticsEvent = event.detail.page.props.flash?.event ?? "";
    const pageComponentName = event.detail.page.component;
    const pageProps = $page.props;
    const pageUrl = event.detail.page.url;

    setTimeout(() => gtag_pageview(analyticsEvent, pageComponentName, pageProps, pageUrl), 150);
  };

}

function resetDataLayer() {
  if (window.dataLayer && Array.isArray(window.dataLayer) && window.dataLayer.length) {
    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push(function () {
      this.reset();
    });
  }
}

function setEcommerce(a, b = null) {
  // this function adds ecommerce element when gtm
  var data = { ecommerce: a };
  if (b) {
    data = Object.assign({ ...a }, { ecommerce: b });
  }
  return data;
}

function delEcommerce(data) {
  // this function removes ecommerce element when default gtag
  let _data = { ...data };
  if (_data?.ecommerce) {
    let a = { ..._data['ecommerce'] };
    delete _data['ecommerce'];
    _data = Object.assign(_data, a);
  }
  return _data;
}

function gsend(event, data, type = 'event') {
  // generalized push to data layer function
  if (gtmEnabled()) {
    // gtm
    if (data?.ecommerce) {
      resetDataLayer();
    }
    let _data = { ...data };
    _data[type] = event;
    gtag(_data);
  } else if (gtagEnabled()) {
    // gtag
    let _data = delEcommerce(data);
    gtag(type, event, _data);
  }
}

function viewItemListEvent(products, listName = "") {

  const pageProps = $page.props;

  const breadcrumbs = pageProps?.breadcrumbs ?? [];
  const crumbGroups = breadcrumbs.map((crumb, index) => {
    if (index) return crumb.name;
  }).filter((n) => n);

  const items = products.map((product, index) => {

    let item = {
      item_id: product.number,
      item_name: product.name,
      brand: product.brand?.name ?? "",
      currency: currency.value,
      discount: parseFloat(product.price_data.discount_amount_with_vat),
      price: parseFloat(product.price_data.price_without_discount_with_vat),
      item_category: pageProps?.name ?? listName,
      item_list_name: pageProps?.name ?? listName,
      index: index + 1,
      quantity: 1,
    };

    let groups = (crumbGroups.length ? crumbGroups : product.groupNames ?? []);
    if (groups.length) {
      groups.forEach((group, index) => {
        item[index ? "item_category" + (index + 1) : "item_category"] =
          group;
      });
    }

    return item;
  });

  let data = { items: items };

  if (items.length) {
    gsend("view_item_list", setEcommerce(data));
  }

}

function getPromotionTemplate(item, index, creativeName = "") {
  return {
    id: item.id,
    name: item?.name ?? "",
    creativeName: creativeName,
    index: index,
  };
}

function basicPromotionEvent(event, item) {

  if (event && item) {

    let data = {
      promotion_id: item.id,
      promotion_name: item.name,
      creative_name: item.creativeName,
      creative_slot: item.index,
    };

    gsend(event, setEcommerce(data));
  }

}

function cartShippingPaymentChanged(eventData) {
  const sendEvent = (event, props) => {
    var data = {
      currency: currency.value,
      value: parseFloat(cart.value.display_price.total_price.total_price_with_vat ?? 0),
      items: cartItems.value,
    };
    data = Object.assign({}, data, props);
    gsend(event, data);
  };

  const shipping = eventData?.shipping;
  const payment = eventData?.payment;

  if (shipping && shipping?.name) {
    sendEvent('add_shipping_info', {
      shipping_tier: shipping.name,
    });
  }

  if (payment && payment?.name) {
    sendEvent('add_payment_info', {
      payment_type: payment.name,
    });
  }
}

function basicItemEvent(event, data) {
  if (data?.items?.length) {

    let a = {
      value: data.value ?? "",
      currency: currency.value,
    };

    let b = { ...data.items };

    gsend(event, setEcommerce(a, b));
  }
}

watch(() => $page.props?.flash?.event, (eventName) => {

  const eventData = $page.props?.flash?.eventData;

  // console.log(eventName, eventData);

  switch (eventName) {
    case 'addToCart':
      //Custobar
      if (window.cstbr) {
        eventData.items.map(item => window.cstbr.push({ type: 'BASKET_ADD', product_id: item.item_id }));
      }
      //Facebook
      if (window.fbq) {
        window.fbq('track', 'AddToCart', { content_ids: eventData.items.map((item) => item.item_id) }, { eventID: $page.props.eventId });//TODO CHECK THAT IDS GO IN HERE
      }
      basicItemEvent("add_to_cart", eventData);
      break;
    case 'removeFromCart':
      basicItemEvent("remove_from_cart", eventData);
      break;
    case 'selectItem':
      basicItemEvent('select_item', eventData);
      break;
    case 'shippingPaymentChanged':
      cartShippingPaymentChanged(eventData);
      break;
  }

}, { deep: true });

// BLOCKEDITOR FUNCTIONS

function defaultBlockElementForPromotion(event, array, creativeName = "") {
  array.forEach((arrayItem, index) => {

    let slots = arrayItem?.slots?.default ?? [];
    let headingProps = slots.find((item) => item?.name === blockEditorblocks.BLOCK_HEADING)?.props ?? [];
    // let descriptionProps = slots.find((item) => item.name === blockEditorblocks.BLOCK_TEXT)?.props ?? [];

    let name = headingProps["headingText"]?.default ?? "";
    // let text = descriptionProps["text"]?.default ?? "";

    let item = {
      id: arrayItem.id,
      name: name,
      creativeName: creativeName,
      index: index,
    };

    basicPromotionEvent(event, item);

  });

}

function blockDynamicBanners(event, array) {
  if (array.length) {
    array.forEach((banner, index) => {

      let props = banner.props ?? [];
      let analytics = props?.['analytics'] ?? null;

      if (item) {
        let item = getPromotionTemplate(analytics, index, promotionCreativeNames.BANNER);
        basicPromotionEvent(event, item);
      }
    });
  }
}

function blockDefaultBanners(event, array) {
  if (array?.length) {
    defaultBlockElementForPromotion(event, array, promotionCreativeNames.BANNER);
  }
}

function blockDefaultGroups(event, array) {
  if (array.length) {
    defaultBlockElementForPromotion(event, array, promotionCreativeNames.PROMO);
  }
}

export function useAnalyticsBlockEvents() {

  const selectPromotionEvent = (parent) => {

    var blocks = $page.props?.blocks ?? [];
    var item = blocks.find(item => item?.name === (parent?.name ?? ""));

    if (item) {

      const event = "select_promotion";
      const array = [item];

      switch (parent.name) {
        case blockEditorblocks.BLOCK_DEFAULT_BANNER:
          blockDefaultBanners(event, array);
          break;
        case blockEditorblocks.BLOCK_DYNAMIC_BANNER:
          blockDynamicBanners(event, array);
          break;
        case blockEditorblocks.BLOCK_GROUP_HIGHLIGHT:
          blockDefaultGroups(event, array);
          break;

      }

    }
  };

  return {
    selectPromotionEvent,
  };
}

// END BLOCKEDITOR FUCTIONS


//ACTION UTILS

export function useAnalyticsEvents() {

  const selectPromotionEvent = (item, index, creativeName = "") => {

    if (item) {
      gsend("select_promotion", {
        promotion_id: item.id,
        promotion_name: item.name,
        creative_name: creativeName,
        creative_slot: index,
      });
    }
  };

  const viewCartEvent = () => {

    let data = {
      currency: currency.value,
      value: parseFloat(cart.value?.display_price.total_price.total_price_with_vat ?? 0),
      items: cartItems.value,
    };

    gsend('view_cart', setEcommerce(data));

  };

  const searchEvent = (value) => {
    gsend("search", {
      search_term: value ?? "",
    });
  };

  return {
    selectPromotionEvent,
    searchEvent,
    viewCartEvent,
  };

}
