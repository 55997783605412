

const promotionCreativeNames = Object.freeze({
  BANNER: "Promotion banner",
  PROMO: "Promotion banner small",
});

const blockEditorblocks = Object.freeze({
  BLOCK_DEFAULT_BANNER: "template_hero",
  BLOCK_DYNAMIC_BANNER: "dynamic_template_hero",
  BLOCK_GROUP_HIGHLIGHT: "container_product_group_highlight",
  BLOCK_PRODUCT_SLIDER: "block_product_slider",
  BLOCK_HEADING: "block_heading",
  BLOCK_TEXT: "block_text",
});

export {
  promotionCreativeNames,
  blockEditorblocks,
};
